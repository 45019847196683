import React from "react";
import { Link } from "gatsby";
import logo from "../../src/assets/logo.svg";
import "../components/layout.css";

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default ({ children }) => ( 
  <div>
    <header>
      <div className="n-wrapper">
        <Link to="/" className="hd-logo">
          <img src={logo} alt="Welcome to Ilesh Mistry's website" title="Welcome to Ilesh Mistry's website" />
          <span class="w-title">Ilesh Mistry</span>
        </Link>
        <nav className="n-links">
          <ul>
            <ListLink to="/" title="Ilesh Mistry Home">Home</ListLink>
            <ListLink to="/about/" title="About Ilesh Mistry">About</ListLink>
            <ListLink to="/posts/" title="Posts by Ilesh Mistry">Posts</ListLink>       
          </ul>
        </nav>
      </div>
    </header>
    <section className="s-main">
      <div className="s-wrapper">
        {children}
      </div>
    </section>
    <footer>
      <div className="n-wrapper">
        <p>If you have any comments, contact me via <a href="https://twitter.com/ileshmistry" rel="noopener noreferrer" target="_blank" title="Follow Ilesh Mistry on Twitter @ileshmistry">Twitter</a> | <a href="https://www.linkedin.com/in/ileshmistry/" rel="noopener noreferrer" target="_blank" title="Connect with Ilesh Mistry on LinkedIn">LinkedIn</a> | <a href="https://github.com/ileshmistry" rel="noopener noreferrer" target="_blank" title="See what Ilesh is doing on GitHub">GitHub</a> | <a href="/rss.xml" rel="noopener noreferrer" target="_blank" title="Subscribe to Ilesh Mistry's blog posts">RSS</a>.</p>
        <p class="footer-small-text">© {new Date().getFullYear()} - Ilesh Mistry - Built with <a href="https://kontent.ai" rel="noopener noreferrer" target="_blank" title="Built with Kontent">Kontent.ai</a> and <a href="https://www.gatsbyjs.org" rel="noopener noreferrer" target="_blank" title="Built with GatsbyJS">GatsbyJS</a></p>
      </div>
    </footer>
  </div>
)
