import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

const Postitem = ({ data }) => {
const item = data.kontentItemPost.elements;
const siteURLPath = data.site.siteMetadata.siteUrl;
const imagePath = item.banner.value[0].url;
let urlSlash = "/";
const urlPath = siteURLPath + urlSlash + data.kontentItemPost.fields.slug + urlSlash;
let taxTermsArray = item.tags.value;
let taxTerms = "";

let schemaArticle = {
  '@context': 'http://schema.org',
  '@type': 'Article',
  author: {
    '@type': 'Person',
    name: 'Ilesh Mistry',
  },
  copyrightHolder: {
    '@type': 'Person',
    name: 'Ilesh Mistry',
  },
  copyrightYear: '2019',
  creator: {
    '@type': 'Person',
    name: 'Ilesh Mistry',
  },
  publisher: {
    '@type': 'Organization',
    name: 'Ilesh Mistry',
    logo: {
      '@type': 'ImageObject',
      url: imagePath,
    },
  },
  datePublished: item.date.value,
  dateModified: item.date.value,
  description: item.metadata__description.value,
  headline: item.basic__heading.value,
  inLanguage: 'en',
  url: urlPath,
  name: item.metadata__title.value,
  image: {
    '@type': 'ImageObject',
    url: imagePath,
  },
  mainEntityOfPage: urlPath,
}

useEffect(() => {
  const itemId = data.kontentItemPost.fields.id;
  const targetUserId = null;
  const count = 5;
  const scenario = "relevant_posts"; // Optional parameter

  // Make the recommendation request
  window.recombeeIntegration({
    type: "SetDefaults",
    databaseId: "mmt-ilesh-personal-site",
    publicToken: "Dl26QfFOkXWpQbClfjYcsbPsXIepb2Qkto9VgpvtmBaEVUN68wEZNJhUlxdG9d5K",
    rapiHostname: "client-rapi.recombee.com:443",
    itemId: itemId,
  });
  window.recombeeIntegration({
    type: "AddDetailView",
  });
  window.recombeeIntegration({
    type: "RecommendItemsToItem",
    itemId: itemId,
    targetItemId: targetUserId,
    count: count,
    scenario: scenario,
  });
}, [data.kontentItemPost.fields.id]);

/*

useEffect(() => {
  const itemId = data.kontentItemPost.fields.id;

  // Make the recommendation request
  window.recombeeIntegration({
    type: "SetDefaults",
    databaseId: "mmt-ilesh-personal-site",
    publicToken: "Dl26QfFOkXWpQbClfjYcsbPsXIepb2Qkto9VgpvtmBaEVUN68wEZNJhUlxdG9d5K",
    rapiHostname: "client-rapi.recombee.com:443",
    itemId: itemId,
  });
  window.recombeeIntegration({
    type: "AddDetailView",
  });
  window.recombeeIntegration({
    type: "InitializeRecommendationWidget",
    widgetId: "5ec5aa1d-abc8-4cc8-806d-859fc4d37abc",
    rootElementId: "widget-root-5ec5aa1d-abc8-4cc8-806d-859fc4d37abc",
  });
}, [data.kontentItemPost.fields.id]);

*/
  
    return (
    <Layout>
        <Helmet>
          <html lang="en" />

          {/* General tags */}
          <title>{item.metadata__title.value} | Ilesh Mistry's website</title>
          <meta name="description" content={item.metadata__description.value} />
          <meta name="image" content={imagePath} />
          <link rel="canonical" href={urlPath} />

          {/* Twitter Card tags */}
          <meta name="twitter:url" content={urlPath} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@ileshmistry" />
          <meta name="twitter:title" content={item.metadata__title.value} />
          <meta name="twitter:description" content={item.metadata__description.value} />
          <meta name="twitter:image" content={imagePath} />
          <meta name="twitter:site" content={siteURLPath + urlSlash} />

          {/* OpenGraph tags */}
          <meta property="og:url" content={urlPath} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={item.metadata__title.value} />
          <meta property="og:description" content={item.metadata__description.value} />
          <meta property="og:image" content={imagePath} />
          <meta property="og:image:secure_url" content={imagePath} />

          {<script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
         {<script>
          {`
            window.recombeeIntegration=window.recombeeIntegration||function(){(recombeeIntegration.q=recombeeIntegration.q||[]).push(arguments)};recombeeIntegration.l=+new Date;
            recombeeIntegration({
              type: "SetDefaults",
              databaseId: "mmt-ilesh-personal-site",
              publicToken: "Dl26QfFOkXWpQbClfjYcsbPsXIepb2Qkto9VgpvtmBaEVUN68wEZNJhUlxdG9d5K",
              rapiHostname: "client-rapi.recombee.com:443",
              itemId: '${data.kontentItemPost.fields.id}'
            });
            recombeeIntegration({
              type: "AddDetailView"
            });
            recombeeIntegration({
              type: "RecommendItemsToItem",
              itemId: '${data.kontentItemPost.fields.id}',
              targetItemId: null,
              count: 5,
              scenario: 'relevant_posts'
            });
          `}
        </script>}
       {<script src="https://cdn.jsdelivr.net/npm/recombee-api-client@4.1.2"></script>}
        </Helmet>
        <div class="post-see-other">
          <Link to="/posts/">
            View other posts
          </Link>
        </div>

        <h1>{item.basic__heading.value}</h1>
        <p>
          {item.date.name}: {item.date.value}
          <br />Reading time: {item.reading_time__mins_.value} mins
        </p>
        <img src={imagePath + "?w=800"} alt={item.banner.value[0].description}/>
        <article dangerouslySetInnerHTML={{ __html: item.basic__content.value }} />
        {taxTermsArray.forEach(element => {
          taxTerms = element.name + ", " + taxTerms;
        })}
        <div class="post-tags">Tags: {taxTerms.replace(/,\s*$/, "")}</div>
        <p></p>
        <h3>Related posts</h3>
        <div id="widget-root-5ec5aa1d-abc8-4cc8-806d-859fc4d37abc"></div>
        <Helmet>
          <script>{`window.recombeeIntegration=window.recombeeIntegration||function(){(recombeeIntegration.q=recombeeIntegration.q||[]).push(arguments)};recombeeIntegration.l=+new Date;`}</script>
          <script>{`
            recombeeIntegration({
              type: "SetDefaults",
              databaseId: "mmt-ilesh-personal-site",
              publicToken: "Dl26QfFOkXWpQbClfjYcsbPsXIepb2Qkto9VgpvtmBaEVUN68wEZNJhUlxdG9d5K",
              rapiHostname: "client-rapi.recombee.com:443",
              itemId: '${data.kontentItemPost.fields.id}'
            });
            recombeeIntegration({
              type: "AddDetailView"
            });
            recombeeIntegration({
              type: "InitializeRecommendationWidget",
              widgetId: "5ec5aa1d-abc8-4cc8-806d-859fc4d37abc",
              rootElementId: "widget-root-5ec5aa1d-abc8-4cc8-806d-859fc4d37abc"
            });`}
  </script>
          <script type="text/javascript" defer src="https://web-integration.recombee.com/v1/recombee.js"></script>
        </Helmet>
    </Layout>
    )
}

export default Postitem

export const query = graphql`
  query postitemQuery($slug: String!) {
    kontentItemPost(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      elements {
        basic__content {
          value
        }
        basic__heading {
          value
        }
        metadata__title {
          value
        }
        metadata__description {
          value
        }
        metadata__keywords {
          value 
        }
        metadata__image {
          value {
              url
          }
        }
        date {
          name
          value(formatString: "DD MMMM YYYY")
        }
        banner {
          value {
              name
              type
              size
              url
              description
          }
        }
        tags {
          name 
          value {
            name
          }
        }
        reading_time__mins_ {
          value
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        locale
        lang
        title
        twitter
        description
        image
        thumbnail
      }
    }
  }
`